.about-hero {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5rem;
  padding: 10rem 5rem;

  &>div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--left {
    align-items: flex-start;

    h2 {
      font-size: 5.5rem;
      font-weight: 500;
      letter-spacing: 5px;
      text-transform: uppercase;
    }

    h3 {
      font-size: 6rem;
      font-weight: 700;
      text-transform: lowercase;
    }

    p {
      margin-top: 1rem;
      font-size: 3rem;
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: flex-start;
    justify-content: center;
  }
}