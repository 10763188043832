$borderRadius: 2rem;

.service-card{
    .servcard{
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: $borderRadius;

        &__picture{
            border-radius: $borderRadius;
            overflow: hidden;
            &--image{
                height: 20rem;
                aspect-ratio: 1.5;
                transform: scale(1.4);
                backface-visibility: hidden;
                transition: all .5s;
            }

            // &:hover &--caption{
            //     opacity: 1;
            //     transform: translate(-50%, -50%);
        
            // }
        
            // &:hover &--image{
            //     transform: scale(1);
            //     filter: blur(3px) brightness(80%);
            // }
        }

        &:hover{
            // GLASS MORPHISIM
            // background: rgba(255, 255, 255, 0.3);
            // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
            // backdrop-filter: blur(20px);
            // -webkit-backdrop-filter: blur(20px);
            // border: 1px solid rgba(255, 255, 255, 0.3);
        }
    
        &:hover .servcard__picture--image{
            transform: scale(1);
            filter: blur(3px) brightness(80%);
        }

        &:hover .servcard__heading{
            font-size: 2.5rem;
            font-weight: 500;
            color: white;
            transform: translateY(-12rem);
        }
        
        &__heading{
            margin-top: 3rem;
            font-size: 3rem;
            font-weight: 200;
            z-index: 1;
            text-transform: uppercase;
            transition: all .5s;
        }
    }
}

@keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 28rem;
    }
    100% {
      background-position: 28rem;
    }
  }
