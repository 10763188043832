.intro {
  position: relative;
  min-height: 70vh;
  padding: 10rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &::after {
    overflow: hidden;
    position: absolute;
    text-align: center;
    content: "about me";
    opacity: 0.05;
    line-height: 1;
    font-size: 12rem;
    top: 40%;
    left: 30%;
    z-index: 1;
    transform: skew(-120deg, -30deg) scaleY(0.667);
    text-shadow: 4px 4px 0px #000, -4px 0 0px #000, 7px 4px 0 #fff;
  }
  &-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  &-profile {
    display: flex;
    justify-content: center;
    align-items: center;

    &-picture {
      text-align: center;
      .profile-picture {
        width: 75%;
      }
    }
  }

  &-desc {
    
    display: flex;
    justify-content: center;
    align-items: center;

    &-heading {
      width: 80%;
      h2 {
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 5px;
        text-transform: uppercase;
      }
      h3 {
        font-size: 4rem;
        font-weight: 700;
        text-transform: lowercase;
      }
      p {
        margin-top: 5rem;
        margin-bottom: 3rem;
        font-size: 2rem;
      }
      .btn {
        font-size: 1.3rem;
      }
    }
  }
}


@media only screen and (max-width: 991px) {
  .intro{
    &-profile {  
      &-picture {
        .profile-picture {
          width: 95%;
        }
      }
    }
  
    &-desc{
      &-heading{
        width: 90%;
      }
    }
  }
}

@media only screen and (max-width: 770px) {
.intro{

  &::after {
    font-size: 10rem;
    left: 20%;
  }


  &-profile {  
    &-picture {
      .profile-picture {
        width: 75%;
      }
    }
  }

  &-desc{
    &-heading{
      width: 95%;
      h2,h3,p {
        text-align: center;
      }
      .btn-cont{
        text-align: center;
      }
    }
  }
}
}

@media only screen and (max-width: 576px) {
  .intro{

    &::after{
      font-size: 6rem;
    }
  }
}


