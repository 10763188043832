.projects-intro{
    margin: 10rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 5rem;
    text-align: center;
    h1{
        font-size: 4rem;
        font-weight: 700;
        text-transform: lowercase;
    }

    h2{
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 5px;
        text-transform: uppercase;
        line-height: 4.5rem;
    }

    h3{
        font-size: 4rem;
    }
}