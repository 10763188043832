.projectMosiac {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
    padding: 1.5rem;
    border-radius: 10px;

    & .wide {
        grid-column: span 2;
    }

    & .tall {
        grid-row: span 2;
    }

    & .big {
        grid-column: span 2;
        grid-row: span 2;
    }

    &>a {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 10px;

        &>img {
            width: 100%;
            height: 100%;
            object-position: top center;
            object-fit: cover;
            border-radius: 10px;
        }

        &>.overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            background: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 35%, rgba(0, 212, 255, 0) 100%);
            color: white;
            display: flex;
            border-radius: 10px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            padding: 1rem;
            transition: all 0.5s linear;

            h1 {
                font-size: 3rem;
                font-weight: 700;
                transition: all 0.5s linear;
                transform: translateY(2rem);
            }

            span {
                font-style: italic;
                font-size: 1.5rem;
                text-align: justify;
                font-weight: 300;
                opacity: 0;
                transition: all 0.5s linear;

            }

        }
    }




}

@media screen and (max-width: 768px) {
    .projectMosiac {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

}


@media (hover: hover) and (pointer: fine) {
    .projectMosiac>a {
        &>.overlay:hover {
            background: rgba(0, 0, 0, 0.4);
            gap: 1rem;

            h1 {
                transform: translateY(0);
            }

            span {
                opacity: 1;
            }

        }
    }
}