.tech-stack{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10rem 5rem;

    &-picture{
        margin-bottom: 3rem;
        img{
            width: 15rem;
        }
    }

    h1{
        font-size: 4rem;
        font-weight: 700;
        text-transform: lowercase;
        margin-bottom: 10rem;
        text-align: center;
    }

    & > div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        position: relative;
        width: 100%;
    }

    h2{
        margin-top: 10rem;
        font-size: 2.5rem;
        font-weight: 500;
        letter-spacing: 5px;
        text-transform: uppercase;
        line-height: 4.5rem;
    }

   
}