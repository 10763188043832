.btn-cont {
  .btn {
    position: relative;
    padding: 1.5rem 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    -webkit-font-smoothing: antialiased;

    &:hover {
      border: none !important;

      .line-1 {
        animation: move1 1500ms infinite ease;
      }

      .line-2 {
        animation: move2 1500ms infinite ease;
      }

      .line-3 {
        animation: move3 1500ms infinite ease;
      }

      .line-4 {
        animation: move4 1500ms infinite ease;
      }
    }
  }

  .line-1 {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    left: 0;
    bottom: 0;
  }
  .line-2 {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    left: 0;
    top: 0;
  }
  .line-3 {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    right: 0;
    top: 0;
  }
  .line-4 {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    right: 0;
    bottom: 0;
  }
}

@keyframes move1 {
  0% {
    height: 100%;
    bottom: 0;
  }
  54% {
    height: 0;
    bottom: 100%;
  }
  55% {
    height: 0;
    bottom: 0;
  }
  100% {
    height: 100%;
    bottom: 0;
  }
}

@keyframes move2 {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes move3 {
  0% {
    height: 100%;
    top: 0;
  }
  54% {
    height: 0;
    top: 100%;
  }
  55% {
    height: 0;
    top: 0;
  }
  100% {
    height: 100%;
    top: 0;
  }
}

@keyframes move4 {
  0% {
    width: 0;
    right: 0;
  }
  55% {
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 100%;
  }
}
