$border-radius: 5rem;
$icon-width: 4.5rem;

.icon-button {
  width: $icon-width;
  height: $icon-width;
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border-radius: $border-radius;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);

  .icon {
    width: $icon-width;
    height: $icon-width;
    float:left;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: $border-radius;

    .svgIcon {
      color: white;
      font-size: $icon-width/2;
    }
  }

  span {
    font-size: $icon-width/2;
    font-weight: 500;
    margin-left: $icon-width/3;
    line-height: $icon-width;
  }
}

.icon-button:hover {
  width: $icon-width * 4;
}