$card-height: 50rem;

.pc-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: $card-height;
    padding: 5rem;
    overflow: hidden;
}

.project-card {
    height: $card-height;
    width: 80%;
    display: block;
    align-items: flex-end;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: scale(1.05);
    position: relative;

    &__image {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;

        &>div {
            height: 100%;
            width: 100%;
            background: linear-gradient(to left, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.8) 50%) right;
            background-size: 200%;
            transition: all .5s ease-in;
            overflow: hidden;

            &::before {
                opacity: 0;
                position: absolute;
                content: attr(data-proj-number);
                font-size: 16rem;
                font-weight: 900;
                transform: translateY(1.5rem);
                left: .5rem;
                top: -4rem;
                color: white;
                text-shadow: 6px 6px 0px rgba(255, 255, 255, 0.5);
                transition: all 0.4s linear;
                transition-delay: 0.3s;
            }
        }

    }

    &__content {
        width: 100%;
        color: white;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        align-items: flex-start;
        padding: 5rem;
        transition: all 1s linear;
        position: absolute;
        background: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 35%, rgba(0, 212, 255, 0) 100%);
        bottom: 2.5rem;


        h1 {
            font-size: 4rem;
            font-weight: 700;
        }

        span {
            font-style: italic;
            font-size: 2rem;
            text-align: justify;
            font-weight: 300;

        }

    }

}

@media only screen and (max-width: 770px) {

    .pc-container {
        padding: 3rem;
    }

    .project-card__content {
        padding: 5rem 3rem;

        h1 {
            font-size: 3rem;
        }

        span {
            font-size: 1.5rem;
        }
    }
}

@media only screen and (max-width: 426px) {

    .pc-container {
        padding: 1.5rem;
    }

    .project-card {
        width: 100%;
    }

    .project-card__content {
        padding: 5rem 2rem;

        h1 {
            font-size: 2.3rem;
        }

        span {
            font-size: 1.2rem;
        }
    }
}

@media (hover: hover) and (pointer: fine) {
    .project-card:hover {
        transform: scale(1);

        .project-card__content {
            transform: translateY(-1.5rem);

            span {
                display: block !important;
            }
        }

        .project-card__image {

            &>div {
                background-position: 0;

                &::before {
                    opacity: 1;
                    transform: translateY(0);
                    background-position: 0;
                }
            }
        }
    }
}