.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .mouse{
    width: 25px;
    height: 40px;
    border: 2px solid #000;
    border-radius: 60px;
    position: relative;
    overflow: hidden;

    &::before{
      content: '';
      width: 5px;
      height: 5px;
      position: absolute;
      top: 7px;
      left: 50%;
      transform: translateX(-50%);
      background-color: currentColor;
      border-radius: 50%;
      opacity: 1;
      animation: wheel 1.3s infinite;
      -webkit-animation: wheel 1.3s infinite;
    }
  }


  // background-image: url('../../assets/banner-bg.png');
  // background-position: top center;
  // background-size: cover;
  // background-repeat: no-repeat;

  overflow: hidden;
  position: relative;
  &::before {
    overflow: hidden;
    position: absolute;
    content: "develop";
    opacity: 0.05;
    font-size: 10rem;
    top: 10%;
    right: 3rem;
    z-index: 1;
    transform: skew(0deg, 30deg) scaleY(1.337);
    text-shadow: 4px 4px 0px #000, -4px 0 0px #000, 7px 4px 0 #fff;
  }

  &::after {
    overflow: hidden;
    position: absolute;
    content: "design";
    opacity: 0.05;

    font-size: 15rem;
    bottom: 40%;
    left: 15%;
    z-index: 1;
    writing-mode: vertical-lr;
    transform: skew(60deg, -30deg) scaleY(0.667);
    text-shadow: 4px 4px 0px #000, -4px 0 0px #000, 7px 4px 0 #fff;
  }

  &-greetings {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    z-index: 2;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-title {
      font-size: 4rem;
    }

    &-subtitle {
      font-size: 6rem;
    }

    &-designation {
      font-size: 3rem;
    }

    &-current {
      margin-top: 2rem;
      font-size: 2rem;
      line-height: 2;
      span {
        margin: 0 1rem;
        padding: 0.4rem 1rem;
        border-radius: 5px;
      }
    }

    &-description {
      margin-top: 2rem;
      font-size: 2rem;
      text-align: justify;
      text-justify: inter-character;
    }
  }

  &-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &-img {
      // padding-bottom: 1rem;
      max-width: 100%;
      max-height: 80%;
    }
    // :hover {
    //   animation: floating 3s ease-in-out infinite;
    // }
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -2px #0dae10;
  }
  to {
    box-shadow: 0 0 10px 2px #0dae10;
  }
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0.8rem, -1rem);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media only screen and (max-width: 1200px) {
  .hero-greetings {

    &-title {
      font-size: 3rem;
    }

    &-subtitle {
      font-size: 4rem;
    }

    &-designation {
      font-size: 2rem;
    }

    &-description {
      font-size: 1.5rem;
    }
  }
}

@media only screen and (max-width: 991px) {
  .hero {
    &::before {
      font-size: 10rem;
      right: 8rem;
    }
    &::after {
      font-size: 15rem;
    }
  }
  .hero-greetings {
    text-align: center;

    &-content {
      align-items: center;
    }

    &-title {
      font-size: 4rem;
    }

    &-subtitle {
      font-size: 6rem;
    }

    &-designation {
      font-size: 3rem;
    }

    &-description {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .hero-profile {
    &-img {
      max-width: 60%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .hero {
    &::before {
      font-size: 6rem;
    }
    &::after {
      font-size: 6rem;
    }
    &-greetings{
      &-designation {
        font-size: 2.2rem;
      }
    }
  }
}

@keyframes wheel{to{opacity:0;top:27px}}

@-webkit-keyframes wheel{to{opacity:0;top:27px}}
