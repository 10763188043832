@mixin common($a, $b, $c, $d, $e, $f, $g) {
  display: $a;
  flex-direction: $b;
  justify-content: $c;
  align-items: $d;
  width: $e;
  height: $f;
  background-color: $g;
  transition: all $time ease;
}

$scale: 120px;
$time: 1s;

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  min-height: 12vh;
}

.header {
  display: flex;
  width: 100%;
  max-width: 90%;
  justify-content: space-between;
  align-items: center;

  .logo {
    text-decoration: none;
    line-height: normal;

    .logo-img {
      height: 7rem;
    }
  }

  .menu-icon {
    display: none;
  }

  .menu {
    font-size: 2.5rem;
    list-style: none;
  }

  .header-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
}
li {
  display: inline-block;
  margin: 2px 5px 2rem 1rem;
  font-weight: 500;

  a {
    padding: 1rem 2rem;
    text-decoration: none;
    border-radius: 5px;
    color: inherit;
    transition: all 0.3s ease-in-out;
  }

  .menu-home {
    &-selected {
      color: #2ab0ee !important;
    }
    &:hover {
      box-shadow: 0 2px 10px #2ab0ee;
      background-color: #2ab0ee;
      color: #ffffff !important;
    }
  }

  .menu-about {
    &-selected {
      color: #eb6559 !important;
    }
    &:hover {
      box-shadow: 0 2px 10px #eb6559;
      background-color: #eb6559;
      color: #ffffff !important;
    }
  }

  .menu-projects {
    &-selected {
      color: #f7b908 !important;
    }
    &:hover {
      box-shadow: 0 2px 10px #f7b908;
      background-color: #f7b908;
      color: #ffffff !important;
    }
  }

  .menu-contact {
    &-selected {
      color: #47a148 !important;
    }
    &:hover {
      box-shadow: 0 2px 10px #47a148;
      background-color: #47a148;
      color: #ffffff !important;
    }
  }
}

.menu-outer {
  display: none;
  margin-top: 2rem;
}

@media (max-width: 991px) {
  .header {
    .logo > .logo-img {
      height: 5rem;
    }

    .menu {
      display: none;
    }
    .menu-icon {
      display: inline-block;
    }
  }

  .menu-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
}
