@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  cursor: none;
}
@media only screen and (max-width: 600px) {
  .html {
    font-size: 50%;
  }
}

body {
  margin: 0;
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.25s linear;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #292a2d;
}

::-webkit-scrollbar-thumb {
  background: #e3405f;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #e3405f;
}

::selection {
  background: #00a1ec;
  color: white;
}

.goto-projects{
  margin-top: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.goto-projects h4{
      margin-top: 2rem;
      margin-bottom: 4rem;
      font-size: 2rem;
      text-transform: uppercase;
      letter-spacing: 1rem;
}

.goto-projects .btn {
    font-size: 1.6rem;
}

.goto-projects h3 {
  font-size: 4rem;
  font-weight: 700;
  text-transform: lowercase;
}



@media screen and (max-width: 768px) {
  .goto-projects{
    margin-top: 10rem;
  }
}
