$logo-size: 8rem;


.footer-container{
    min-height: 50vh;
    display: flex;
    justify-content: center;
    padding: 6rem 1rem 2rem 1rem;
}

.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__brand{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &__row{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 4rem;
        .logo{
            display: flex;
            align-items: center;
            justify-content: center;
            &-img{
                width: $logo-size;
                height: $logo-size;
                animation: rotateSOut 0.8s normal forwards ease-in;

                &:hover{
                  animation: rotateSIn 0.8s normal forwards ease-in;
                  
                }
            }
        }
        h1{
            font-size: 2.5rem;
            letter-spacing: 1rem;
            text-transform: uppercase;
            padding: 1.5rem 4.5rem;
            background: linear-gradient(to right, #4d4d4d 0, #fff 10%, #4d4d4d 20%);
            background-position: 0;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: shine 3s infinite linear;
            animation-fill-mode: forwards;
            -webkit-text-size-adjust: none;
        }

    }


    &__social{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    &__credits{
        padding-top: 10rem;
        span{
            font-size: 1.5rem;
        }
    }

    
}

  @keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 28rem;
    }
    100% {
      background-position: 28rem;
    }
  }

@keyframes spin {
    100% {
      transform:rotate(1turn) translate(12rem) rotate(-1turn);
    }
  }

  @keyframes rotateSOut{
    0%{
      transform: rotate(360deg);
      height: $logo-size;
      width: $logo-size;
      content: url("../../assets/mgs.svg");
    }
    90%{
      height: $logo-size;
      width: $logo-size;
      content: url("../../assets/rvg_logo.svg");
    }
    100%{
      transform: rotate(0deg);
      height: $logo-size;
      width: $logo-size;
      content: url("../../assets/rvg_logo.svg");
    }
  }
  
  @keyframes rotateSIn{
    0%{
      transform: rotate(0deg);
    }
    90%{
      height: $logo-size;
      width: $logo-size;
      content: url("../../assets/mgs.svg");
    }
    100%{
      transform: rotate(360deg);
      height: $logo-size;
      width: $logo-size;
      content: url("../../assets/mgs.svg");
    }
  }