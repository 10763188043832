.projects-display{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding: 5rem;
}


@media only screen and (max-width: 770px) {

    .projects-display{
        padding: 3rem;
    }
}