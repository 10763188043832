
.tech-card{

    height: 20rem;
    aspect-ratio: 1/1.15;
    border-radius: 1rem;
    transition: all 0.8s;
    overflow: hidden;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: inherit;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: 10rem;

    

    &::after{
        opacity: 0;
        content: attr(data-tech-name);
        position: absolute;
        text-align: center;
        width: 100%;
        left: -5.5rem;
        transform: rotate(270deg);
        font-size: 2.6rem;
        font-style: italic;
        font-weight: 700;
        text-transform: uppercase;
        transition: all 0.8s;

    }

    &--content{

        h2{
            margin: 2rem;
            opacity: 0;
            transition: opacity 1s;
        }
        
    }
}

@media (hover:hover){
    // target devices which can hover

    .tech-card{

        &-container{
            h3{
                display: none;
            }
        }

        &:hover{
            background-position: 4.5rem center;
            background-size: 25rem;
            border: 2px solid currentColor;
    
            &::after{
                opacity: 1;
            }
        }
    }
}

@media (hover:none){
    // target devices which cant hover

    .tech-card{

        

        &-container{
            padding: 1rem 0;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
            border: 2px solid currentColor;
            h3{
                display: flex;
                justify-content: center;
                width: 100%;
                font-size: 2.5rem;
            }
        }
    }
}