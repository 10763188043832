.skills-overview{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    h2{
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 5px;
        text-transform: uppercase;
        line-height: 4.5rem;
    }
    h3 {
        font-size: 4rem;
        font-weight: 700;
        text-transform: lowercase;
      }
}

.skills{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8rem;
    margin: auto;
    margin-top: 8rem;
    
}

.skill-bg{
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;

}


@keyframes bgEnter {
    from{
        background-size: 0%;
    }
    to{
        background-size: 100%;
    }
}

