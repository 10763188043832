.experience-tile{

    &-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 3rem;
    }

    &-image{
        width: 8rem;
        aspect-ratio: 1/1;
        background-size:cover;
        padding: 1rem;
        border-radius: 999px;

    }

    &-desc{
        h2{
            font-size: 2.6rem;
        }

        h3{
            font-size:2rem;
            font-weight: 500;
            letter-spacing: 5px;
            text-transform: uppercase;
        }
        p{
            font-size: 1.8rem;

        }
    }
}